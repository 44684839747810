import required from './rules/required'
import requiredSelection from './rules/requiredSelection'
import strlength from './rules/strlength'
import strlenMin from './rules/strlenMin'
import strlenMax from './rules/strlenMax'
import strlenRange from './rules/strlenRange'
import leastSelectedLength from './rules/leastSelectedLength'
import numRange from './rules/numRange'
import numPositive from './rules/numPositive'
import numNotNegative from './rules/numNotNegative'
import confirmPassword from './rules/confirmPassword'
import existAccount from './rules/existAccount'
import numThanSize from './rules/numThanSize'
import numMoreThan from './rules/numMoreThan'
import noSpaces from './rules/noSpaces'
import onlyNum from './rules/onlyNum'
import onlyNumEn from './rules/onlyNumEn'
import onlyNumEnSign from './rules/onlyNumEnSign'
import onlyNumEnCnDash from './rules/onlyNumEnCnDash'
import titleContent from './rules/titleContent'
import date from './rules/date'
import onlyNumEnCn from './rules/onlyNumEnCn'
import noZero from './rules/noZero'
import noSymbol from './rules/noSymbol'
import ruleForRS_01 from './rules/ruleForRS'
import noRepeatedSettings from './rules/noRepeatedSettings'
import dateTime from './rules/dateTime'
import dateTimeRangeForStart from './rules/dateTimeRangeForStart'
import dateTimeRangeForEnd from './rules/dateTimeRangeForEnd'
import url from './rules/url'

/**
 * 驗證方法，新寫的記得 import 完要補進來才有作用
 */
const rules = {
  required,
  strlength,
  requiredSelection,
  strlenMin,
  strlenMax,
  strlenRange,
  leastSelectedLength,
  numRange,
  numPositive,
  numNotNegative,
  confirmPassword,
  existAccount,
  numThanSize,
  numMoreThan,
  noSpaces,
  onlyNum,
  onlyNumEn,
  onlyNumEnSign,
  onlyNumEnCnDash,
  titleContent,
  date,
  onlyNumEnCn,
  noZero,
  noSymbol,
  ruleForRS_01,
  noRepeatedSettings,
  dateTime,
  dateTimeRangeForStart,
  dateTimeRangeForEnd,
  url
}

/**
 * 取得驗證規則
 * @param name 驗證名稱
 */
function get (name: string): Function {
  const rule = rules[name]
  if (rule) {
    return rules[name]
  }
  throw Error(`makeValidator missing Rule: "${name}"`)
}

export { get, rules }
